.swiper-slide {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.swiper-pagination-bullet {
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    background-color: var(--color-black);
    border-radius: 50%;
    margin-right: 0.44rem;

    &-active {
        background-color: var(--color-red);
    }
}

.slider {
    position: relative;
    max-width: 100%;
    overflow-x: hidden;

    &--how-it-works,
    &--dashboard {
        .swiper-slide {
            margin: 0 2.57vw 0;
            width: 80.37%;
            cursor: grab;
        }
    }

    &--how-it-works {
        padding-bottom: min(13.55vmin, 3.63rem);
    }

    &--dashboard {
        padding-bottom: min(14.95vw, 4rem);

        .swiper-pagination-bullet {
            background-color: var(--color-white);

            &-active {
                background-color: var(--color-red);
            }
        }
    }
}

.form-slider {
    .swiper-slide {
        display: block;
        height: calc(var(--app-height) - var(--header-height));
    }
}

.swiper-pagination {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    width: 100%;
}
