app-button {
    display: flex;
    justify-content: center;
}

.icon-button {
    background-color: transparent;
    outline: none;
    padding: 0;

    &--close {
        width: min(4.2vw, 1.13rem);
        height: min(4.2vw, 1.13rem);
    }
}

%footer-button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 0;
    overflow: hidden;

    background: var(--color-red);
}

.button {
    $button: &;
    font-size: var(--font-size-14);
    font-weight: var(--weight-semibold);
    border: 1px solid var(--color-white);
    color: var(--color-white);
    background: transparent;
    width: 57.29vw;
    max-width: 15.31rem;
    padding-top: clamp(10px, 3.04vw, 13px);
    padding-bottom: clamp(11px, 3.3vw, 14.2px);
    padding-left: min(7.47vw, 2.5rem);
    padding-right: min(7.47vw, 2.5rem);
    transition: all 0.3s ease-in-out;
    outline: none;
    line-height: 1.36;

    &.active {
        background-color: var(--color-white);
        color: var(--color-black);
    }

    &--dark {
        background-color: var(--color-white);
        color: var(--color-black);

        &.active {
            background-color: var(--color-black);
            color: var(--color-white);
            border-color: var(--color-black);
        }
    }

    &--dark-border {
        border-color: var(--color-black);
    }

    &--transparent {
        background: transparent;
    }
}

.footer-button {
    @extend %footer-button;
    height: min(44.86vmin, 12rem);
}

.qr-button {
    min-width: min(38.83vw, 10.39rem);
    width: unset;
    max-width: unset;
    padding-left: 0;
    padding-right: 0;
    background-color: var(--color-white);
    color: var(--color-black);

    &--completed {
        background-color: var(--color-red);
        color: var(--color-white);
        border-color: var(--color-red);
    }
}

.expanding-footer-button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 0;
    min-height: 0;
    overflow: hidden;

    background: var(--color-red);
    transition: min-height 0.3s ease;

    &--show {
        min-height: 44.86vw;
    }
}
