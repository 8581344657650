// Dynamic Quiz styling

app-quiz-choice {
    .choice {
        .large-text {
            font-size: var(--font-size-30);
            font-weight: var(--weight-bold);
        }

        sup {
            font-weight: var(--weight-normal);
            font-size: 50%;
        }

        .medium-text {
            font-size: var(--font-size-28);
        }

        strong {
            font-weight: var(--weight-bold);
        }

        &--selected * {
            color: var(--color-white);
        }

        .racing-series {
            font-size: clamp(11px, 3.12vw, 14px);
            display: block;
            line-height: 20px;
        }
    }
}
