/* You can add global styles to this file, and also import other style files */

@use "swiper/scss" as *;
@use "./scss/custom-styles.scss" as *;

@import "scss/mixins";

* {
    box-sizing: border-box;
}

// Handle notch on certain mobile devices
@supports (padding: max(0px)) {
    body,
    header,
    footer {
        padding-left: min(0vw, env(safe-area-inset-left));
        padding-right: min(0vw, env(safe-area-inset-right));
    }
}

html {
    overflow: hidden;
}

body,
html {
    width: 100%;
    height: 100%;
    margin: 0;
}

body {
    font-family: "Toyota Type", Arial, Helvetica, sans-serif;
    color: var(--color-black);
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-button {
        display: none;
    }
}

.horizontal-padding {
    padding-left: var(--gutter);
    padding-right: var(--gutter);
}

.horizontal-margin {
    margin-left: var(--gutter);
    margin-right: var(--gutter);
}

.page {
    padding-top: var(--header-height);
    overflow-y: auto;
    height: var(--app-height, 100vh);
}

.page-hero {
    position: relative;
    width: 100%;

    &__content {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 100%;
    }
}

img {
    max-width: 100%;
    width: 100%;
    height: auto;
}

hr {
    margin-top: min(3.39vw, 0.9rem);
    margin-bottom: 0;
    width: min(9.81vw, 2.63rem);
    height: 2px;
    background-color: var(--color-red);
}

sup {
    font-size: 80%;
    vertical-align: super;
}

// Animations
.fade-in {
    animation: fadeIn 0.3s ease-in forwards;
}

@keyframes fadeIn {
    from {
        z-index: -1;
        opacity: 0;
    }

    to {
        z-index: 1;
        opacity: 1;
    }
}
