.header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: var(--header-height);

    padding-left: var(--header-gutter-left);
    padding-right: var(--header-gutter-right);

    background-color: var(--color-white);
    z-index: 100;

    &--nav,
    &--qr {
        background-color: var(--color-black);
    }

    &--nav {
        position: relative;
    }

    &--qr {
        justify-content: flex-end;
    }
}

.logo {
    flex-basis: var(--header-logo-width);
    width: var(--header-logo-width);
    height: auto;
}
