h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

.text {
    font-size: var(--font-size-16);
    font-weight: var(--weight-normal);
    line-height: 1.19;

    &--xxs {
        font-size: 8px;
    }

    &--xxs-10 {
        font-size: 10px;
    }

    &--xs {
        font-size: var(--font-size-12);
        font-weight: var(--weight-normal);
    }

    &--sm {
        font-size: var(--font-size-14);
        font-weight: var(--weight-normal);
        line-height: 1.26;
    }

    &--md {
        font-size: var(--font-size-18);
        font-weight: var(--weight-book);
    }

    &--lg {
        font-size: var(--font-size-20);
        font-weight: var(--weight-semibold);
    }

    &--xl {
        font-size: var(--font-size-24);
        font-weight: var(--weight-semibold);
    }

    &--2xl {
        font-size: var(--font-size-32);
        font-weight: var(--weight-normal);
    }

    &--3xl {
        font-size: var(--font-size-40);
        font-weight: var(--weight-semibold);
        line-height: 1.25;
    }

    &--4xl {
        font-size: var(--font-size-46);
        font-weight: var(--weight-bold);
        line-height: 1.15;
    }

    &--white {
        color: var(--color-white);
    }

    &--red {
        color: var(--color-red);
    }

    &--centered {
        text-align: center;
    }
}

.uppercase {
    text-transform: uppercase;
}

// Unscoped text styles
.dynamic-styles {
    .large-text {
        font-size: var(--font-size-30);
        font-weight: var(--weight-bold);
    }

    sup {
        font-weight: var(--weight-normal);
        font-size: 50%;
    }

    .medium-text {
        font-size: var(--font-size-28);
    }

    strong {
        font-weight: var(--weight-bold);
    }

    .extra-line-height {
        line-height: 1.5;
    }

    &--selected * {
        color: var(--color-white);
    }
}
