.form-content {
    padding: 0 var(--form-content-gutter);
    height: 100%;
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-top: min(3.27vw, 14px);
}

.form-field {
    display: flex;
    align-items: center;
    justify-self: flex-start;
    width: 100%;
    height: min(15.42vmin, 66px);
    border: 1px solid var(--color-black);
    padding-right: min(7.2vmin, 30.9px);
    padding-left: min(6.1vmin, 26px);
    font-size: var(--font-size-16);
    font-weight: var(--weight-book);
    outline: none;

    &.ng-touched.ng-invalid {
        border-color: var(--color-red);
    }

    &::placeholder {
        color: inherit;
    }
}

.select-options {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow-y: scroll;

    &--grid {
        --gap: min(3.27vw, 14px);
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: max-content;
        column-gap: var(--gap);
        row-gap: var(--gap);
        padding: min(2.34vw, 10px) min(7.01vw, 30px) min(9.81vw, 42px);
    }
}

.select-option {
    display: flex;
    align-items: center;
    min-height: 34px;
    padding: 0 min(6.07vw, 26px);
    font-size: var(--font-size-14);
    font-weight: var(--weight-book);

    &--active {
        background-color: #f2f2f2;
    }
}

input,
select {
    outline: none;
    // comment
}

// Custom Radio buttons
.c-radio-list,
.radio-row {
    margin-left: min(22.9vw, 98px);
}

.c-radio-list {
    display: flex;
}

.radio-row {
    display: flex;
    align-items: center;
    margin-top: min(4.9vw, 21px);

    .radio-button:last-child {
        margin-left: min(14.02vw, 60px);
    }
}

.radio-list {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
}

.radio-button {
    display: flex;
    position: relative;
    padding-left: 22px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: var(--font-size-16);
    font-weight: var(--weight-book);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.radio-button {
    &__indicator {
        /* Create a custom checkbox */
        --indicator-size: 23px;

        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        justify-content: center;
        align-items: center;
        height: var(--indicator-size);
        width: var(--indicator-size);
        border-radius: 50%;
        background-color: var(--color-white);
        border: 1px solid var(--color-black);
    }

    &__label {
        margin-left: 10px;
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
}

/* On mouse-over, add a grey background color */
.radio-button:hover input ~ .radio-button__indicator {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.radio-button input:checked ~ .radio-button__indicator {
    background-color: var(--color-white);
}

/* Create the checkmark/indicator (hidden when not checked) */
.radio-button__indicator:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the radio indicator when checked */
.radio-button input:checked ~ .radio-button__indicator:after {
    display: block;
}

/* Style the radio indicator/indicator */
.radio-button .radio-button__indicator:after {
    position: relative;
    width: 81%;
    height: 81%;
    border-radius: 50%;
    background-color: var(--color-red);
}
