@font-face {
    font-family: "Toyota Type";
    src: url("../assets/fonts/ToyotaType-Regular.otf") format("opentype"),
        url("../assets/fonts/ToyotaType-Regular.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Toyota Type";
    src: url("../assets/fonts/ToyotaType-Book.otf") format("opentype"),
        url("../assets/fonts/ToyotaType-Book.ttf") format("truetype");
    font-weight: 350;
}

@font-face {
    font-family: "Toyota Type";
    src: url("../assets/fonts/ToyotaType-Semibold.otf") format("opentype"),
        url("../assets/fonts/ToyotaType-Semibold.ttf") format("truetype");
    font-weight: 600;
}
@font-face {
    font-family: "Toyota Type";
    src: url("../assets/fonts/ToyotaType-Bold.otf") format("opentype"),
        url("../assets/fonts/ToyotaType-Bold.ttf") format("truetype");
    font-weight: 700;
}
