:root {
    --color-black: #000000;
    --color-white: #ffffff;
    --color-red: #eb0a1e; // Toyota Red
    --color-grey: #9a9a9a;
    --color-light-grey: #f4f4f4;
    --color-progress: #80858a;
    --color-success: #22bb33;
    --color-light-blue: #428ef5;

    // Font variables
    --weight-book: 350;
    --weight-normal: 400;
    --weight-semibold: 600;
    --weight-bold: 700;

    // Based on 428px design width with 75% minimum value or 10px (whichever is larger)
    --font-size-8: clamp(8px, 1.87vw, 10px);
    --font-size-10: 10px;
    --font-size-12: clamp(10px, 2.8vw, 12px);
    --font-size-14: clamp(11px, 3.27vw, 14px);
    --font-size-16: clamp(12px, 3.74vw, 16px);
    --font-size-18: clamp(14px, 4.2vw, 18px);
    --font-size-20: clamp(16px, 4.67vw, 20px);
    --font-size-24: clamp(18px, 5.6vw, 24px);
    --font-size-28: clamp(21px, 6.54vw, 28px);
    --font-size-30: clamp(22.5px, 7vw, 30px);
    --font-size-32: clamp(24px, 7.48vw, 32px);
    --font-size-40: clamp(30px, 9.34vw, 40px);
    --font-size-46: clamp(36px, 10.75vw, 46px);

    --header-height: min(15.88vw, 4.25rem);

    // gutters
    --gutter: 1.31rem;
    --header-gutter-left: var(--gutter);
    --header-gutter-right: 1.88rem;
    --header-logo-width: min(27.04vw, 7.23rem);

    --form-content-gutter: min(4.9vw, 21px);

    --column-gap: 4.44vw;
    --row-gap: 4.44vw;
}
